<template>
  <div>
    <h5>ข่าวประกาศ</h5>
    <div v-for="item in items" :key="item._id" class="card border-warning">
      <div class="p-2 alert-warning d-flex justify-content-between">
        <strong>เรื่อง: {{item.subject}}</strong>
        <span>วันที่ {{item.createdAt | dispDateTime('DD/MM/YYYY HH:mm')}} น.</span>
      </div>
      <div class="card-body p-2">
        <NewsContent :data="item.htmlDetails" />
      </div>
    </div>
  </div>
</template>
<script>
import NewsService from '@/services/NewsService'
import NewsContent from './NewsContent'

export default {
  name: 'News',
  components: {
    NewsContent
  },
  data() {
    return {
      data: null
    }
  },
  computed: {
    items() {
      if(!this.data)
        return []

      return this.data
    }
  },
  methods: {
    getNews() {
      NewsService.getNews()
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }
      })
    }
  },
  mounted() {
    this.getNews()
  }
}
</script>
