<template>
  <div class="container pb-5">

    <div class="alert alert-warning border-warning">
      <h5>ประกาศสำคัญ!</h5>
      กรุณาศึกษา<router-link to="/help">คู่มือการใช้งาน</router-link> และปฏิบัติตามกฎกติกาของทางบริษัทอย่างเคร่งครัด หากบริษัทตรวจสอบพบการทำผิดกฏจะทำการ <span class="text-danger font-weight-bold">ยกเลิกรายการเล่นทุกกรณี</span>
    </div>

    <h4>ยินดีต้อนรับ! <span class="text-primary">{{ UserInfo.firstName }} {{ UserInfo.lastName }}</span></h4>
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <span>จำนวนเงิน</span>
            <div class="d-flex justify-content-between">
              <div class="text-info">
                <i class="fas fa-2x fa-dollar-sign"></i>
              </div>
              <h3
                class="mb-0"
                :class="[{'text-danger': Balance<0}, {'text-success': Balance>0}]"
              >{{Balance | amountFormat}}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <span>สมาชิก</span>
            <div class="d-flex justify-content-between">
              <div class="text-info">
                <i class="fas fa-2x fa-users"></i>
              </div>
              <h3 class="mb-0 text-info">{{Member}}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <span>ออนไลน์</span>
            <div class="d-flex justify-content-between">
              <div class="text-info">
                <i class="fas fa-2x fa-user-friends"></i>
              </div>
              <h3 class="mb-0 text-success">{{Online}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h5>ผลงวดหวยวันนี้</h5>
    <table class="table table-info table-bordered table-hover">
      <thead>
        <tr>
          <th colspan="2">หวย</th>
          <th>งวด</th>
          <th>เวลาเปิด</th>
          <th>เวลาปิด</th>
          <th>3 ตัวบน</th>
          <th>2 ตัวล่าง</th>
          <th>สถานะ</th>
        </tr>
      </thead>
      <tbody v-for="group in groups" :key="group.group._id" class="text-secondary">
        <tr>
          <td colspan="8" class="alert-warning">{{group.group.groupTitle}}</td>
        </tr>
        <tr v-for="item in group.items" :key="item._id">
          <td width="70" class="p-1">
            <img :src="imageIcon(item)" style="width: 60px;">
          </td>
          <td class="px-1 py-0">{{item.market.marketTitle}}</td>
          <td class="text-center px-1 py-0">{{item.roundDate.date | dispDateTime("DD-MM-YYYY")}}</td>
          <td class="text-center px-1 py-0">{{item.roundDate.open | dispDateTime("DD/MM/YYYY")}}<br />{{item.roundDate.open | dispDateTime("HH:mm:ss")}}</td>
          <td class="text-center px-1 py-0">{{item.roundDate.close | dispDateTime("DD/MM/YYYY")}}<br />{{item.roundDate.close | dispDateTime("HH:mm:ss")}}</td>
          <td v-if="item.marketId.marketType === 'single'" class="text-center px-1 py-0">
            <span class="badge fullsize badge-info">{{ item | result('threeNumberTop') }}</span>
          </td>
          <td v-if="item.marketId.marketType === 'single'" class="text-center px-1 py-0">
            <span class="badge fullsize badge-info">{{ item | result('twoNumberBottom') }}</span>
          </td>
          <td v-if="item.marketId.marketType === 'lottoset'" colspan="2" class="text-center px-1 py-0">{{ item | result('fourNumber') }}</td>
          <td class="text-center px-1 py-0">
            <span class="badge" :class="item.roundStatus.badge">{{item.roundStatus.text}}</span>
          </td>
        </tr>
      </tbody>
      <tbody v-if="!groups.length && !isLoading">
        <tr>
          <td colspan="8" class="text-center">ยังไม่ออกผล</td>
        </tr>
      </tbody>
    </table>

    <News />
  </div>
</template>
<script>
import RoundService from "@/services/RoundService"
import _ from 'lodash'
import moment from '@/helpers/moment'
import News from '@/views/news/components/News'

export default {
  name: 'Dashboard',
  components: {
    News
  },
  data() {
    return {
      isLoading: false,
      data: null,
      date: moment().add(-5, 'h').format("YYYY-MM-DD")
    }
  },
  computed: {
    UserInfo() {
      return this.$store.state.userProfile
    },
    Balance() {
      return this.$store.state.account.balance
    },
    Member() {
      return this.$store.state.account.member
    },
    Online() {
      return this.$store.state.account.online
    },
    marketGroups() {
      return this.$store.state.marketGroups
    },
    markets() {
      return this.$store.state.markets
    },
    groups() {
      if(!this.data)
        return []

      const items = _.orderBy(this.data.filter((item)=>{
          return moment().isAfter(item.roundDate.close)
        })
        .map((item)=>{
          item.market = this.markets.find((m)=>{
            return item.marketId._id === m._id
          })

          const roundStatus = {
            badge: 'badge-secondary',
            text: ''
          }

          if(item.status === 'Open') {
            roundStatus.badge = 'badge-info'
            roundStatus.text = 'รอออกผล'
          }else
          if(item.status === 'Close') {
            roundStatus.badge = 'badge-info'
            roundStatus.text = 'รอออกผล'
          }else
          if(item.status === 'Cancel') {
            roundStatus.badge = 'badge-danger'
            roundStatus.text = 'ยกเลิก'
          }else
          if(item.status === 'Refund') {
            roundStatus.badge = 'badge-primary'
            roundStatus.text = 'คืนเงิน'
          }else
          if(item.status === 'Complete') {
            roundStatus.badge = 'badge-primary'
            roundStatus.text = 'กำลังออกผล'
          }else
          if(item.status === 'Paid') {
            roundStatus.badge = 'badge-success'
            roundStatus.text = 'จ่ายเงินแล้ว'
          }

          item.roundStatus = roundStatus
          return item
      }), ['roundDate.close'], ['asc'])

      const groups = _.groupBy(items, (round)=>{
        return round.groupId
      })

      const rounds = []
      for(const groupId in groups) {
        rounds.push({
          group: this.marketGroups.find((g)=>{
            return g._id === groupId
          }),
          items: groups[groupId]
        })
      }

      return _.orderBy(rounds, ['group.sort'], ['asc'])
    }
  },
  methods: {
    getResults() {
      this.isLoading = true
      RoundService.getResults(this.date)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    imageIcon(item) {
      return item?.market?.imageIcon
    }
  },
  mounted() {
    this.getResults()
  },
  filters: {
    result(market, type) {

      if(market.status === 'Cancel') {
        return 'ยกเลิก'
      }else
      if(market.status === 'Refund') {
        return 'คืนเงิน'
      }

      if(!market?.results[type])
        return ''

      return market.results[type].join('-')
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        vertical-align: middle;
        line-height: 1.2;

        .badge {
          font-size: 95%;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
